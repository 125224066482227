I18n.translations || (I18n.translations = {});
I18n.translations["th"] = I18n.extend((I18n.translations["th"] || {}), {
  "activerecord": {
    "attributes": {
      "errors": {
        "models": {
          "user": {
            "attributes": {
              "reset_password_token": {
                "invalid": "token หมดอายุการใช้งาน"
              }
            }
          }
        }
      },
      "kpi": {
        "actived": "สถานะการใช้งาน",
        "actived_date": "วันเดือนปี ที่เริ่มใช้ตัวชี้วัด",
        "benchmark": "Benchmark (ค่า/แหล่งอ้างอิง/ปี) (ภาษาอังกฤษ)",
        "benchmark_th": "Benchmark (ค่า/แหล่งอ้างอิง/ปี) (ภาษาไทย)",
        "code": "รหัสตัวชี้วัด",
        "disease_code": "รหัสโรค / รหัสหัตถการที่เกี่ยวข้อง (ตัวตั้ง, ตัวหาร) (ภาษาอังกฤษ)",
        "disease_code_th": "รหัสโรค / รหัสหัตถการที่เกี่ยวข้อง (ตัวตั้ง, ตัวหาร) (ภาษาไทย)",
        "flag_from_thip_1": "ตัวชี้วัดจาก THIP I",
        "interpret_method": "วิธีการแปลผล (ภาษาอังกฤษ)",
        "interpret_method_th": "วิธีการแปลผล (ภาษาไทย)",
        "meaning": "นิยาม / คำอธิบาย / ความหมายของตัวชี้วัด (ภาษาอังกฤษ)",
        "meaning_th": "นิยาม / คำอธิบาย / ความหมายของตัวชี้วัด (ภาษาไทย)",
        "name": "ชื่อสถานพยาบาล (ภาษาอังกฤษ)",
        "name_th": "ชื่อสถานพยาบาล (ภาษาไทย)",
        "note": "หมายเหตุ (ภาษาอังกฤษ)",
        "note_th": "หมายเหตุ (ภาษาไทย)",
        "objective": "วัตถุประสงค์ของการมีตัวชี้วัดนี้ (ภาษาอังกฤษ)",
        "objective_th": "วัตถุประสงค์ของการมีตัวชี้วัดนี้ (ภาษาไทย)",
        "record_method": "ข้อมูลที่ต้องการ (ตัวตั้ง, ตัวหาร) (ภาษาอังกฤษ)",
        "record_method_th": "ข้อมูลที่ต้องการ (ตัวตั้ง, ตัวหาร) (ภาษาไทย)",
        "source": "ที่มาของตัวชี้วัด / Reference (ภาษาอังกฤษ)",
        "source_th": "ที่มาของตัวชี้วัด / Reference (ภาษาไทย)",
        "updated_at": "วันเดือนปี ที่ปรับปรุงครั้งล่าสุด",
        "updated_reason": "เหตุผลของการปรับปรุง (ภาษาอังกฤษ)",
        "updated_reason_th": "เหตุผลของการปรับปรุง (ภาษาไทย)"
      },
      "kpi_inputs": {
        "name": "ชื่อ (ภาษาอังกฤษ)",
        "name_th": "ชื่อ (ภาษาไทย)"
      },
      "post": {
        "actived": "การใช้งาน",
        "category": "ประเภท",
        "content": "เนื้อหา",
        "cover": "ภาพหน้าปก",
        "created_at": "สร้างเมื่อ",
        "end_date": "วันที่สิ้นสุดประกาศข่าว",
        "ended": "ตั้งค่าวันที่สิ้นสุดประกาศข่าว",
        "pinned": "ปักหมุด",
        "pinned_carousel": "ปักหมุดที่ Slideshow",
        "send_mails": "ส่งอีเมลไปยังสมาชิค",
        "title": "หัวข้อ",
        "updated_at": "แก้ไขเมื่อ"
      },
      "registered_hospital": {
        "kpi_templates": "ตัวชี้วัด"
      },
      "screened_out": {
        "kpi_id": "ตัวชี้วัด"
      },
      "user": {
        "confirmation_sent_at": "คำแนะนำในการยืนยันตัวตนถูกส่งไปเมื่อ",
        "confirmation_token": "รหัสลับสำหรับยืนยันตัวตน",
        "confirmed_at": "ยืนยันตัวตนเมื่อ",
        "created_at": "สร้างขึ้นเมื่อ",
        "current_password": "รหัสผ่านปัจจุบัน",
        "current_sign_in_at": "เข็าสู่ระบบเมื่อ",
        "current_sign_in_ip": "IP ที่ใช้เข้าสู่ระบบ",
        "email": "อีเมล",
        "encrypted_password": "รหัสผ่านที่ถูกเข้ารหัส",
        "failed_attempts": "การเข้าสู่ระบบล้มเหลว",
        "last_sign_in_at": "เข้าสู่ระบบล่าสุดเมื่อ",
        "last_sign_in_ip": "IP ที่เข้าสู่ระบบล่าสุด",
        "locked_at": "ถูกล็อกเมื่อ",
        "password": "รหัสผ่าน",
        "password_confirmation": "ยืนยันรหัสผ่าน",
        "password_old": "รหัสผ่านเก่า",
        "remember_created_at": "การจำผู้ใช้ครั้งล่าสุด",
        "remember_me": "จำชื่อผู้ใช้",
        "reset_password_sent_at": "คำสั่งการเปลี่ยนรหัสผ่านถูกส่งไปเมื่อ",
        "reset_password_token": "รหัสลับสำหรับการเปลี่ยนรหัสผ่าน",
        "sign_in_count": "จำนวนครั้งที่เข้าสู่ระบบ",
        "unconfirmed_email": "อีเมลที่ไม่ได้รับการยืนยัน",
        "unlock_token": "รหัสลับสำหรับปลดล็อก",
        "updated_at": "แก้ไขเมื่อ"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "ไม่สามารถสร้างข้อมูลได้"
      },
      "models": {
        "hospital": {
          "attributes": {
            "code": {
              "taken": "รหัสสถานพยาบาลนี้ถูกใช้งานแล้ว"
            },
            "name": {
              "blank": "กรุณากรอก"
            }
          }
        },
        "kpi": {
          "attributes": {
            "code": {
              "taken": "รหัสตัวชี้วัดได้ถูกสร้างขึ้นในปีงบประมาณนี้แล้ว"
            }
          }
        },
        "kpi_sheet_input": {
          "attributes": {
            "value": {
              "greater_than": "จำนวนต่ำเกินกำหนด",
              "less_than": "จำนวนสูงเกินกำหนด"
            }
          }
        },
        "kpi_sheet_rollback": {
          "attributes": {
            "base": {
              "kpi_empty": "กรุณาเลือกตัวชี้วัด"
            }
          }
        },
        "project": {
          "attributes": {
            "name": {
              "taken": "ชื่อโครงการร่วมนี้ได้ถูกสร้างขึ้นในปีงบประมาณนี้แล้ว"
            }
          }
        },
        "registered_hospital": {
          "attributes": {
            "code": {
              "overdue": "มีการสมัครใช้งาน หรืออยู่ในขั้นตอนรออนุมัติ",
              "taken": "รหัสสถานพยาบาลได้ถูกสร้างขึ้นในปีงบประมาณนี้แล้ว"
            },
            "kpi_templates": {
              "blank": "กรุณาเลือก"
            }
          }
        },
        "special_group": {
          "attributes": {
            "name": {
              "taken": "ชื่อกลุ่มนี้ได้ถูกสร้างขึ้นในปีงบประมาณนี้แล้ว"
            }
          }
        },
        "user": {
          "attributes": {
            "email": {
              "blank": "กรุณากรอกอีเมล ไม่สามารถเป็นค่าว่างได้",
              "taken": "อีเมลนี้ถูกใช้งานแล้ว"
            },
            "password": {
              "too_short": "ต้องมีอย่างน้อย 6 ตัว"
            },
            "password_confirmation": {
              "confirmation": "ไม่ตรงกัน"
            }
          }
        }
      }
    },
    "models": {
      "user": "ผู้ใช้งาน"
    }
  },
  "analytics": {
    "area": "เขต",
    "budget_year": "ปีงบประมาณ",
    "budget_year_value": "ประจำปีงบประมาณ %{budget_year}",
    "control_chart": "Control Chart",
    "dashboard": {
      "bar_chart": "กราฟแท่งแสดงการเปรียบเทียบภาพรวมของตัวชี้วัด",
      "label": "Dashboard",
      "line_chart": "กราฟเส้นแสดงการเปรียบเทียบภาพรวมของตัวชี้วัด",
      "result_in_range": "ค่าอยู่ในระดับที่"
    },
    "delete_filter": "ลบการค้นหาที่บันทึก",
    "delete_filter_success": "ลบการค้นหาที่บันทึกสำเร็จ",
    "empty_kpi": "ไม่มีข้อมูลตัวชี้วัด",
    "end_round": "ช่วงสิ้นสุดออกรายงาน",
    "export_excel": "ออกรายงานเป็น Excel",
    "export_pdf": "ออกรายงานเป็น PDF",
    "find_kpi": "ค้นหาตัวชี้วัด",
    "group": "กลุ่ม",
    "kpi_progress": {
      "kpi_name": "ชื่อตัวชี้วัด",
      "kpi_value": "KPI Value",
      "label": "รายงานตัวชี้วัดย้อนหลัง",
      "present": "ปีปัจจุบัน",
      "year_1": "ปีปัจจุบัน - 1",
      "year_2": "ปีปัจจุบัน - 2",
      "year_3": "ปีปัจจุบัน - 3",
      "year_4": "ปีปัจจุบัน - 4"
    },
    "kpi_value": {
      "kpi_value": "KPI Value",
      "label": "รายงานข้อมูลสถิติภาพรวม",
      "median": "Median",
      "n": "N",
      "p25": "P25",
      "p75": "P75"
    },
    "month": "เดือน",
    "month_budget_year_amount": "เดือน%{month} ปีงบประมาณ %{budget_year} จำนวน %{amount} รพ.",
    "new_member_hospital": "จำนวน รพ.สมาชิกใหม่ %{value}",
    "paid_hospital": "จำนวน ที่ชำระเงินแล้ว %{value} รพ. จำนวนเงิน %{amount} บาท",
    "period_type": "ประเภทช่วงเวลา",
    "region": "ภูมิภาค",
    "renew_member_hospital": "จำนวน รพ.ต่ออายุ %{value}",
    "report": {
      "active": "ใช้งาน",
      "all_kpi_number": "จำนวนตัวชี้วัดทั้งหมด",
      "all_kpi_value": "ค่าตัวชี้วัดทั้งหมด",
      "all_user": "จำนวนผู้ใช้งานทั้งหมด",
      "amount": "จำนวนเงิน",
      "approve_number": "จำนวนที่อนุมัติ",
      "area": "เขตสุขภาพ",
      "central_line": "Central Line",
      "changwat": "จังหวัด",
      "data_range": "ช่วงเวลาของข้อมูล",
      "disapprove_number": "จำนวนที่ไม่อนุมัติ",
      "financial": "รายงานการเงิน",
      "general": "รายงานทั่วไป",
      "group": "กลุ่มสมาชิก",
      "hos_code": "รหัสสถานพยาบาล",
      "hos_name": "ชื่อสถานพยาบาล",
      "hos_number": "จำนวนโรงพยาบาล",
      "hos_unit": "รพ.",
      "hospital_member": "รายงานจำนวนโรงพยาบาลสมาชิก",
      "hospital_payment_status": "รายงานจำนวนและรายละเอียดสมาชิกที่สมัครสมาชิกใหม่ที่ชำระเงินแล้ว / ยังไม่ชำระ",
      "hospital_register": "รายงานข้อมูลการสมัคร / ต่ออายุสมาชิก",
      "hospital_regiter_status": "รายงานสถานะการลงทะเบียนของสมาชิก",
      "hospital_select_kpi": "รายงานการแสดงค่าตัวชี้วัดที่สมาชิกเลือก",
      "hospital_send_group_kpi": "รายงานร้อยละการส่งข้อมูลเฉลี่ยในแต่ละประเภทสมาชิก",
      "hospital_send_select_kpi": "รายงานการแสดงค่าตัวชี้วัดที่สมาชิกเลือกและส่งข้อมูลเข้าสู่ระบบ",
      "inactive": "ระงับการใช้งาน",
      "kpi": "ตัวชี้วัด",
      "kpi_area": "รายงานการแสดงข้อมูลแบบเทียบเคียงเขตสุขภาพ",
      "kpi_best10": "รายงานการแสดงค่า Best Practice > 10 (มากไปน้อย , น้อยไปมาก)",
      "kpi_choice": "รายงานลำดับตัวชี้วัดที่ถูกเลือกเปรียบเทียบจากมากไปหาน้อย",
      "kpi_group": "รายงานค่าสถิติตัวชี้วัดรายกลุ่มสมาชิก",
      "kpi_region": "รายงานการแสดงค่าตัวชี้วัดตนเองเมื่อเทียบภูมิภาค",
      "kpi_report": "รายงานตัวชี้วัด",
      "kpi_save_status": "รายงานสถานะการบันทึกตัวชี้วัด",
      "kpi_size": "รายงานการแสดงค่าตัวชี้วัดตนเองเมื่อเทียบขนาดเตียง",
      "kpi_subgroup": "รายงานค่าสถิติตัวชี้วัดข้ามกลุ่มสมาชิก (Sub Group)",
      "kpi_top_send": "รายงานการแสดงตัวชี้วัดที่มีอัตราการส่งข้อมูลมากที่สุด (เลือก, ส่ง)",
      "kpi_unit": "รายงานการแสดงค่าตัวชี้วัดตนเองเมื่อเทียบกับสังกัดอื่น",
      "kpi_value": "ค่าตัวชี้วัด",
      "kpi_value_en": "Kpi Value",
      "kpi_value_list": "รายงานค่าสถิติแบบเลือก",
      "kpi_value_report": "รายงานการแสดงค่าตัวชี้วัด",
      "label": "รายงาน",
      "max": "Max",
      "mean": "Mean",
      "median": "Median",
      "min": "Min",
      "n": "N",
      "new_member": "สมาชิกใหม่",
      "not_paid": "ยังไม่ชำระเงิน",
      "not_select_kpi_number": "จำนวนตัวชี้วัดที่ไม่ได้เลือก",
      "paid": "ชำระเงินแล้ว",
      "paid_member": "รายงานสมาชิกที่ชำระเงินแล้ว",
      "payment_seq": "รหัสใบจ่ายเงิน",
      "payment_status": "สถานะการจ่ายเงิน",
      "payment_status_sheet": "Hospitals",
      "percentage": "ร้อยละ",
      "percentile_25": "Percentile 25",
      "percentile_75": "Percentile 75",
      "reason_1": "เหตุผล 1",
      "reason_2": "เหตุผล 2",
      "reason_3": "เหตุผล 3",
      "reason_4": "เหตุผล 4",
      "reason_5": "เหตุผล 5",
      "reason_6": "เหตุผล 6",
      "region": "ภูมิภาค",
      "renew": "ต่ออายุ",
      "rollback_request_number": "จำนวนที่ขอปลดล็อคทั้งหมด",
      "rollback_request_reasons": "รายงานรายละเอียดการปลดล็อคระบบ",
      "sd1": "SD1",
      "sd1_neg": "-SD1",
      "sd2": "SD2",
      "sd2_neg": "-SD2",
      "sd3": "SD3",
      "sd3_neg": "-SD3",
      "select_kpi_number": "จำนวนตัวชี้วัดที่เลือก",
      "selected_kpi_member": "รายชื่อสมาชิกที่เลือกตัวชี้วัด",
      "send_kpi_nubmer": "จำนวนตัวชี้วัดที่ส่ง",
      "send_percentage": "อัตราร้อยละการส่ง",
      "size": "ขนาดเตียง",
      "time": "ครั้ง",
      "total": "รวม",
      "total_member": "ยอดรวมที่สมัคร",
      "trim_mean": "Trim Mean",
      "type": "ประเภท",
      "unit": "สังกัด",
      "unlock": "รายงานการปลดล็อคระบบ",
      "users": "รายงานผู้ใช้งานระบบ"
    },
    "round": "รอบการส่ง%{round}",
    "save_filter": "บันทึกการค้นหา",
    "save_filter_success": "บันทึกการค้นหาสำเร็จ",
    "select_kpi": "เลือกตัวชี้วัด",
    "size": "ขนาดเตียง",
    "special_group": "กลุ่มพิเศษ",
    "start_round": "ช่วงเริ่มออกรายงาน",
    "total_amount": "จำนวนเงินทั้งหมด %{amount} บาท",
    "total_hospital": "จำนวน รพ.ทั้งหมด %{value}",
    "unit": "สังกัด",
    "unpaid_hospital": "จำนวน ที่ยังไม่ชำระเงิน %{value} รพ. จำนวนเงิน %{amount} บาท",
    "user_amount": "จำนวนผู้ใช้งานระบบ %{value} คน",
    "year": "ปีออกรายงาน",
    "year_type": "ประเภทปี"
  },
  "app": {
    "abbrev": "THIP",
    "about": "อธิบายเกี่บวกับที่มาของโครงการ",
    "about_thip": "เกี่ยวกับ THIP",
    "about_us": "เกี่ยวกับเรา",
    "about_us_en": "About Us",
    "about_us_no_data": "ไม่มีข้อมูล About Us ในขณะนี้",
    "accept": "ยอมรับ",
    "add": "เพิ่ม",
    "approve": "อนุมัติ",
    "are_you_sure": "คุณแน่ใจหรือไม่?",
    "back": "กลับ",
    "bath": "บาท",
    "benchmarking": "Benchmarking",
    "can_not_delete": "ไม่สามารถลบได้",
    "cancel": "ยกเลิก",
    "come_be_part_of_thip": "มาร่วมเป็นส่วนหนึ่งกับ THIP",
    "contact_location": "เลขที่ 88/39 อาคารสุขภาพแห่งชาติ ชั้น 5 ซอย 6 บริเวณกระทรวงสาธารณสุข ถนนติวานนท์ ตำบลตลาดขวัญ อำเภอเมืองนนทบุรี 11000.",
    "contact_tel": "โทรศัพท์ : 0-2832-9400 โทรสาร : 0-2832-9540",
    "contact_us": "ติดต่อเรา",
    "delete": "ลบ",
    "disapprove": "ไม่อนุมัติ",
    "download": "Download",
    "edit": "แก้ไข",
    "en_name": "(ภาษาอังกฤษ)",
    "export_data": "ส่งออกข้อมูล",
    "failed": "ไม่สำเร็จ",
    "have_no": "ไม่มี",
    "home": "หน้าหลัก",
    "knowledge": "Knowledge",
    "kpi": "ตัวชี้วัด",
    "login": "เข้าสู่ระบบ",
    "login_or_register": "เข้าสู่ระบบหรือสมัครสมาชิก",
    "member": "โรงพยาบาลสมาชิก",
    "month": "เดือน",
    "month_year": "เดือน/ปี",
    "monthly": "รายเดือน",
    "name": "Thailand Hospital Indicator Program",
    "name_alt": "ระบบสารสนเทศเปรียบเทียบวัดระดับคุณภาพโรงพยาบาล (THIP)",
    "name_th": "ระบบสารสนเทศเปรียบเทียบวัดระดับคุณภาพโรงพยาบาล",
    "name_th_alt": "ระบบสารสนเทศเปรียบเทียบวัดระดับคุณภาพโรงพยาบาล (THIP)",
    "next": "ต่อไป",
    "no_data": "ไม่มีข้อมูล",
    "not_select": "ไม่เลือก",
    "not_send": "ไม่ส่ง",
    "not_sent": "ยังไม่ส่ง",
    "not_specified": "ไม่ระบุ",
    "note": "หมายเหตุ",
    "ok": "ยืนยัน",
    "organization_detail_1": "© สถาบันรับรองคุณภาพสถานพยาบาล (องค์การมหาชน) : The Healthcare Accreditation Institute (Public Organization)",
    "organization_detail_2": "โทรศัพท์ 0-2832-9400 , โทรสาร 0-2832-9540",
    "organization_detail_alt": "สถาบันรับรองคุณภาพสถานพยาบาล (องค์การมหาชน)",
    "organization_name": "The Healthcare Accreditation Institute (Public Organization)",
    "other": "อื่นๆ",
    "pdpa_policy": "นโยบายการคุ้มครองข้อมูลส่วนบุคคล (PDPA)",
    "person": "คน",
    "please_select": "กรุณาเลือก",
    "reason": "เหตุผล",
    "report": "รูปแบบรายงาน",
    "save": "บันทึก",
    "save_failed": "บันทึกข้อมูล ไม่สำเร็จ",
    "save_successful": "บันทึกข้อมูล สำเร็จ",
    "search": "ค้นหา",
    "select": "เลือก",
    "select_all": "เลือกทั้งหมด",
    "send": "ส่ง",
    "sent": "ส่งแล้ว",
    "sent_data": "ส่งข้อมูล",
    "specified": "ระบุ",
    "succeed": "สำเร็จ",
    "thip_are_support": "THIP สนับสนุน",
    "thip_detail_1": "เครื่องมือเทคโนโลยีระบบสารสนเทศเปรียบเทียบวัดระดับคุณภาพโรงพยาบาล",
    "thip_detail_2": "ให้เกิดการการพัฒนาระบบบริการสุขภาพมีคุณภาพ และไว้วางใจได้ด้วยมาตรฐาน HA",
    "thip_detail_3": "\"มีวัตถุประสงค์เพื่อส่งเสริมให้สถานพยาบาลใช้ประโยชน์จากการเปรียบเทียบตัวชี้วัด (benchmarking) ในการ ยกระดับผลลัพธ์ขององค์กร พัฒนาตัวชี้วัดเปรียบเทียบสำหรับสถานพยาบาลในประเทศไทยที่จะเป็นเครื่องมือ ชี้ทิศทางและเร่งรัดการพัฒนาคุณภาพบริการสุขภาพ พัฒนาองค์ความรู้จากการปฏิบัติที่เป็นเลิศ และพัฒนากลไก เครื่องมือเทคโนโลยีระบบสารสนเทศเปรียบเทียบวัดระดับคุณภาพโรงพยาบาล\"",
    "thip_today": "THIP Today",
    "to": "ถึง",
    "what_is_thip": "THIP คือ?",
    "year": "ปี",
    "yearly": "รายปี"
  },
  "budget_year": {
    "created_notice": "เพิ่มปีงบประมาณสำเร็จ",
    "deadline_date": "วันสุดท้ายสำหรับการบันทึกข้อมูล",
    "destroyed_notice": "ลบปีงบประมาณสำเร็จ",
    "label": "ปีงบประมาณ",
    "month": "เดือน",
    "note": "คำอธิบายสำหรับแจ้งให้โรงพยาบาลสมาชิกทราบ",
    "title": "ข้อมูลปีงบประมาณ",
    "updated_notice": "แก้ไขปีงบประมาณสำเร็จ"
  },
  "change_passsword": {
    "updated_notice": "เปลี่ยน รหัสผ่านเรียบร้อย"
  },
  "change_password": {
    "updated_notice": "เปลี่ยนรหัสผ่านสำเร็จ"
  },
  "config": {
    "budget_year": {
      "add": "เพิ่มปีงบประมาณ",
      "allow_until_date": "อนุญาตให้มีการแก้ไขข้อมูลการลงทะเบียนจนถึงวันที่",
      "fee": "ค่าลงทะเบียน",
      "group_unit": "ระดับศักยภาพ",
      "info": "ข้อมูลปีงบประมาณ",
      "invoice_code": "รหัสใบจ่ายเงิน",
      "invoice_order": "ลำดับเลขที่จ่ายเงิน",
      "label": "ปีงบประมาณ",
      "payment_end_date": "วันที่สุดท้ายในการชำระเงิน",
      "project_code": "รหัสโครงการ",
      "register_status": "สถานะการเปิดการลงทะเบียน",
      "running_number": "จำนวนหลัก Running Number",
      "start_fill_form_date": "วันที่เริ่มกรอกข้อมูล",
      "title": "ตั้งค่าปีงบประมาณ"
    },
    "fee": {
      "can_not_add_to_list": "ไม่สามารถเพิ่มสถานพยาบาลนี้ในรายการยกเว้นค่าลงทะเบียนได้",
      "except_fee": "ยกเว้นค่าลงทะเบียน"
    },
    "hospital_group": {
      "edit": "แก้ไขตัวชี้วัดกลุ่ม",
      "edit_member": "แก้ไขสมาชิกกลุ่ม"
    },
    "kpi": {
      "edit": "แก้ไขข้อมูลตัวชี้วัด"
    },
    "member": {
      "edit": "แก้ไขสมาชิก"
    },
    "other": {
      "admin": "ผู้ดูแลระบบ",
      "admin_type": "ประเภทผู้ดูแลระบบ",
      "description": "คำอธิบาย",
      "home_page": "หน้าแรกเมื่อเข้าสู่ระบบ",
      "usage_status": "สถานะการใช้งาน",
      "user_type": "ประเภทผู้ใช้งาน"
    },
    "program": {
      "add": "เพิ่มโปรเเกรม",
      "budget_year": "ปีงบประมาณ",
      "description": "รายละเอียด",
      "edit": "แก้ใขโปรเเกรม",
      "info": "ข้อมูลโปรเเกรม อื่นๆ",
      "name": "ชื่อโปรเเกรม",
      "title": "โปรเเกรม อื่นๆ"
    },
    "project": {
      "add": "เพิ่มโครงการร่วม",
      "budget_year": "ปีงบประมาณ",
      "created_notice": "เพิ่มโครงการร่วมสำเร็จ",
      "description": "รายละเอียด",
      "destroyed_notice": "ลบโครงการร่วมสำเร็จ",
      "edit": "แก้ใขโครงการร่วม",
      "edit_kpi": "แก้ไขตัวชี้วัดโครงการร่วม",
      "info": "ข้อมูลโครงการ",
      "name": "ชื่อโครงการ",
      "title": "โครงการร่วม",
      "updated_kpi_notice": "แก้ไขข้อมูลตัวชี้วัดสำเร็จ",
      "updated_notice": "แก้ไขโครงการร่วมสำเร็จ"
    },
    "special_group": {
      "add": "เพิ่มกลุ่มพิเศษ",
      "budget_year": "ปีงบประมาณ",
      "edit": "แก้ใขกลุ่มพิเศษ",
      "edit_kpi": "แก้ไขตัวชี้วัดกลุ่ม",
      "edit_member": "แก้ไขสมาชิกกลุ่ม",
      "info": "ข้อมูลกลุ่มพิเศษ",
      "name": "ชื่อกลุ่ม",
      "title": "กลุ่มพิเศษ"
    },
    "system": {
      "cc_internal_authen": "cc.internal.authen",
      "database_version": "database.version",
      "domain": "system.domain",
      "external_cc_url": "external.cc.url",
      "external_ianalysis_url": "external.ianalysis.url",
      "external_jrs_url": "external.jrs.url",
      "hospital_all_mailgroup": "hospital.all.mailgroup",
      "hospital_all_mailgroup_extends": "hospital.all.mailgroup.extends",
      "log": "log",
      "log_access": "log.access",
      "log_access_console": "log.access.console",
      "log_access_header": "log.access.header",
      "log_console": "log.console",
      "log_full_trace": "log.full.trace",
      "register_payin_company_code": "register.payin.company.code",
      "register_payin_ref2_number": "register.payin.ref2.number",
      "title": "ตั้งค่าระบบ",
      "trial_period": "จำนวนวันทดลองใช้งาน",
      "upload_failed": "บันทึกไม่สำเร็จ",
      "upload_success": "บันทึกสำเร็จ"
    }
  },
  "date": {
    "abbr_month_names": [
      null,
      "ม.ค.",
      "ก.พ.",
      "มี.ค.",
      "เม.ย.",
      "พ.ค.",
      "มิ.ย.",
      "ก.ค.",
      "ส.ค.",
      "ก.ย.",
      "ต.ค.",
      "พ.ย.",
      "ธ.ค."
    ],
    "formats": {
    },
    "month_names": [
      null,
      "มกราคม",
      "กุมภาพันธ์",
      "มีนาคม",
      "เมษายน",
      "พฤษภาคม",
      "มิถุนายน",
      "กรกฎาคม",
      "สิงหาคม",
      "กันยายน",
      "ตุลาคม",
      "พฤศจิกายน",
      "ธันวาคม"
    ],
    "period_types": [
      "รายเดือน",
      "รายปี",
      "รายไตรมาส"
    ],
    "year_types": [
      "ปีปฏิทิน",
      "ปีงบประมาณ"
    ]
  },
  "devise": {
    "confirmations": {
      "confirmed": "คุณได้ยืนยันบัญชีของคุณเรียบร้อยแล้ว ขณะนี้คุณสามารถเข้าสู่ระบบได้",
      "new": {
        "resend_confirmation_instructions": "ส่งยืนยันคำขออีกครั้ง"
      },
      "send_instructions": "คุณจะได้รับอีเมลแนะนำวิธีการยืนยันบัญชีภายในไม่ไม่ช้า",
      "send_paranoid_instructions": "หากอีเมลของคุณมีอยู่ในฐานข้อมูลในระบบแล้ว คุณจะได้รับอีเมลแนะนำวิธีการยืนยันบัญชีภายในไม่ช้า"
    },
    "failure": {
      "already_authenticated": "คุณเข้าสู่ระบบแล้ว",
      "inactive": "บัญชีของคุณยังไม่ถูกเปิดใช้งาน",
      "invalid": "%{authentication_keys} หรือรหัสผ่านไม่ถูกต้อง",
      "last_attempt": "คุณสามารถผิดพลาดได้อีกครั้งก่อนที่บัญชีของคุณจะถูกล็อก",
      "locked": "บัญชีของคุณถูกล็อก",
      "not_found_in_database": "%{authentication_keys} หรือรหัสผ่านไม่ถูกต้อง",
      "timeout": "หน้าเว็บเกินระยะเวลาที่กำหนด กรุณาเข้าสู่ระบบอีกครั้งเพื่อดำเนินการต่อ",
      "unauthenticated": "คุณต้องเข้าสู่ระบบหรือลงทะเบียนก่อนดำเนินการต่อ",
      "unconfirmed": "คุณต้องยืนยันบัญชีก่อนดำเนินการต่อ",
      "user": {
        "actived": "บัญชีของคุณถูกปิดใช้งาน"
      }
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "ยืนยันบัญชีของฉัน",
        "greeting": "ยินดีต้อนรับคุณ %{recipient}!",
        "instruction": "คุณสามารถยืนยันอีเมลที่ใช้สมัครสมาชิกได้จากลิงค์ข้างล่างนี้:",
        "subject": "คำแนะนำการยืนยัน"
      },
      "email_changed": {
        "greeting": "สวัสดี %{recipient}",
        "message": "เราติดต่อคุณในครั้งนี้เพื่อแจ้งให้ทราบว่าอีเมลของคุณได้ถูกเปลี่ยนเป็น %{email} สำเร็จแล้ว",
        "message_unconfirmed": "เราติดต่อคุณในครั้งนี้เพื่อแจ้งให้ทราบว่าอีเมลของคุณจะถูกเปลี่ยนเป็น %{email}",
        "subject": "การเปลี่ยนแปลงอีเมลสำเร็จ"
      },
      "password_change": {
        "greeting": "สวัสดี %{recipient}",
        "message": "เราติดต่อคุณในครั้งนี้เพื่อแจ้งให้ทราบว่ารหัสผ่านของคุณได้ถูกเปลี่ยนสำเร็จแล้ว",
        "subject": "การเปลี่ยนแปลงรหัสผ่านสำเร็จ"
      },
      "reset_password_instructions": {
        "action": "เปลี่ยนรหัสผ่านของฉัน",
        "greeting": "สวัสดีคุณ%{recipient}!",
        "instruction": "ทางเวบได้รับคำขอเปลี่ยนรหัสผ่านของคุณ และคุณสามารถตั้งรหัสผ่านใหม่จากลิงค์ข้างล่างนี้",
        "instruction_2": "ถ้าคุณไม่ได้ขอเปลี่ยนรหัสผ่าน กรุณาเพิกเฉยต่อเมล์ฉบับนี้",
        "instruction_3": "รหัสผ่านของคุณยังไม่ได้ถูกเปลี่ยนแปลง จนกว่าคุณจะเข้าลิงค์ด้านบนนี้และตั้งรหัสผ่านใหม่",
        "subject": "คำแนะนำการตั้งค่ารหัสผ่าน"
      },
      "unlock_instructions": {
        "action": "ปลดล็อคบัญชีของฉัน",
        "greeting": "สวัสดีคุณ%{recipient}!",
        "instruction": "คลิกลิงค์ทางด้านล่างนี้เพื่อปลดล็อคบัญชีของคุณ:",
        "message": "บัญชีของคุณโดนล็อค เนื่องจากคุณใส่รหัสผ่านผิดเกินจำนวนครั้งที่ทางเรากำหนด",
        "subject": "คำแนะนำการปลดล็อก"
      }
    },
    "omniauth_callbacks": {
      "failure": "ไม่สามารถยืนยันตัวคุณจาก %{kind} เพราะ \"%{reason}\"",
      "success": "ยืนยันตัวตนจากบัญชี %{kind}"
    },
    "passwords": {
      "edit": {
        "change_my_password": "เปลี่ยนรหัสผ่านของคุณ",
        "change_your_password": "เปลี่ยนรหัสผ่านของคุณ",
        "confirm_new_password": "ยืนยันรหัสผ่านใหม่",
        "new_password": "รหัสผ่านใหม่"
      },
      "new": {
        "forgot_your_password": "ลืมรหัสผ่าน?",
        "send_me_reset_password_instructions": "ส่งคำขอตั้งรหัสผ่านใหม่"
      },
      "no_token": "คุณไม่สามารถเข้าถึงหน้าเว็บนี้โดยไม่ได้คลิกลิงค์จากอีเมลตั้งค่ารหัสผ่าน หากคุณคลิกลิงค์จากอีเมลตั้งค่ารหัสผ่านกรุณาตรวจสอบให้แน่ใจว่า URL ถูกต้องตามที่เราให้ไว้",
      "send_instructions": "คุณจะได้รับอีเมลแนะนำการตั้งค่ารหัสผ่านในไม่ช้า",
      "send_paranoid_instructions": "หากอีเมลของคุณมีอยู่ในฐานข้อมูลในระบบแล้ว  คุณจะได้รับลิงค์กู้รหัสผ่านคืนในไม่ช้า",
      "updated": "รหัสผ่านของคุณเปลี่ยนแปลงเรียบร้อยแล้ว ขณะนี้คุณสามารถเข้าสู่ระบบโดยใช้รหัสผ่านใหม่ได้",
      "updated_not_active": "รหัสผ่านของคุณเปลี่ยนแปลงเรียบร้อยแล้ว"
    },
    "registrations": {
      "destroyed": "ลาก่อน! บัญชีของคุณถูกยกเลิกเรียบร้อยแล้ว เราหวังว่าจะได้พบคุณใหม่ในไม่ช้า",
      "edit": {
        "are_you_sure": "คุณแน่ใจหรือไม่?",
        "cancel_my_account": "ยกเลิกบัญชีของฉัน",
        "currently_waiting_confirmation_for_email": "ขณะนี้ ระบบรอการยืนยันอีเมล %{email} อยู่",
        "leave_blank_if_you_don_t_want_to_change_it": "เว้นว่างไว้ถ้าคุณไม่ต้องการจะเปลี่ยนแปลง",
        "title": "แก้ไข%{resource}",
        "unhappy": "ไม่พอใจ",
        "update": "ปรับข้อมูล",
        "we_need_your_current_password_to_confirm_your_changes": "เราต้องการรหัสผ่านปัจจุบันเพื่อยืนยันการเปลี่ยนรหัสของคุณ"
      },
      "new": {
        "sign_up": "ออกจากระบบ"
      },
      "signed_up": "ยินดีต้อนรับ! คุณลงทะเบียนเรียบร้อยแล้ว",
      "signed_up_but_inactive": "คุณลงทะเบียนเรียบร้อยแล้ว แต่การเข้าสู่ระบบล้มเหลวเนื่องจากบัญชีของคุณยังไม่ได้เปิดใช้งาน",
      "signed_up_but_locked": "คุณลงทะเบียนเรียบร้อยแล้ว แต่การเข้าสู่ระบบล้มเหลวเนื่องจากบัญชีของคุณล็อก",
      "signed_up_but_unconfirmed": "ข้อความพร้อมลิงค์สำหรับยืนยันได้ส่งไปยังอีเมลของคุณแล้ว กรุณาคลิกลิงค์นั้นเพื่อเปิดใช้งานบัญชี",
      "update_needs_confirmation": "คุณได้อัพเดทบัญชีของคุณเรียบร้อยแล้ว แต่คุณต้องทำการยืนยันอีเมลใหม่นี้ กรุณาตรวจสอบอีเมลและคลิกลิงค์เพื่อยืนยันที่อยู่อีเมลใหม่",
      "updated": "คุณอัพเดตบัญชีของคุณประสบความสำเร็จ",
      "updated_but_not_signed_in": null
    },
    "sessions": {
      "already_signed_out": "ออกจากระบบเรียบร้อยแล้ว",
      "new": {
        "sign_in": "เข้าสู่ระบบ"
      },
      "signed_in": "เข้าสู่ระบบเรียบร้อยแล้ว",
      "signed_out": "ออกจากระบบเรียบร้อยแล้ว"
    },
    "shared": {
      "links": {
        "back": "กลับ",
        "didn_t_receive_confirmation_instructions": "ไม่ได้รับการยืนยันคำขอ?",
        "didn_t_receive_unlock_instructions": "ไม่ได้รับคำขอปลดล็อค?",
        "forgot_your_password": "ลืมรหัสผ่าน?",
        "sign_in": "เข้าสู่ระบบ",
        "sign_in_with_provider": "เข้าสู่ระบบด้วย%{provider}",
        "sign_up": "ออกจากระบบ"
      },
      "minimum_password_length": "(จำนวนตัวอักษรขั้นต่ำ %{count} ตัวอักษร)"
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "ส่งคำขอปลดล็อคอีกครั้ง"
      },
      "send_instructions": "คุณจะได้รับอีเมลแนะนำการปลดล็อกบัญชีในไม่ช้า",
      "send_paranoid_instructions": "หากบัญชีของคุณมีอยู่ในระบบแล้ว คุณจะได้รับอีเมลแนะนำวิธีการปลดล็อกบัญชีในไม่ช้า",
      "unlocked": "บัญชีของคุณถูกปลดล็อกเรียบร้อยแล้ว กรุณาเข้าสู่ระบบเพื่อดำเนินการต่อ"
    }
  },
  "erp": {
    "all_data": "ข้อมูลนำเข้าทั้งหมด",
    "all_export": "ส่งออกทั้งหมด",
    "amount": "จำนวนรับชำระ",
    "data_download_error": "ดาวน์โหลดข้อมูลผิดพลาด",
    "duplicate_data": "ข้อมูลซ้ำซ้อน",
    "failed_to_download": "ดาวน์โหลดข้อมูลไม่ผ่าน",
    "import_transaction_file": "นำเข้า Transaction",
    "paid_date": "วันที่รับชำระ",
    "passed_data": "ข้อมูลผ่าน",
    "record": "รายการ",
    "selected_export": "ส่งออกที่เลือก",
    "send_to_erp_succeed": "ส่งข้อมูลไปยัง ERP เสร็จแล้ว",
    "sent_states": {
      "all_data": "ทั้งหมด",
      "succeed": "ส่งสำเร็จ",
      "unsuccessful": "ส่งไม่สำเร็จ",
      "waiting": "รอส่ง"
    },
    "status": "สถานะ",
    "thead_ref_1": "ลำดับเลขที่จ่ายเงิน (Ref 1)",
    "thead_ref_2": "รหัสใบจ่ายเงิน (Ref 2)",
    "unpassed_data": "ข้อมูลไม่ผ่าน",
    "upload_failed_notice": "อัพโหลดข้อมูลไม่สำเร็จ เนื้องจากตรวจสอบไม่ผ่าน หรือ ไม่เจอข้อมูล"
  },
  "error": {
    "access_denied": "Access Denied",
    "bad_request": "Bad Request",
    "can_not_save": "เกิดข้อผิดพลาดไม่สามารถบันทึกข้อมูลได้",
    "internal_server_error": "Internal Server Error",
    "not_found": "Page Not Found",
    "server_error_try_again": "ข้อผิดพลาดบนเซิร์ฟเวอร์ กรุณาลองใหม่อีกครั้ง",
    "title": "เกิดข้อผิดพลาด",
    "unprocessable_entity": "Unprocessable Entity"
  },
  "errors": {
    "messages": {
      "already_confirmed": "ยืนยันแล้ว ลองเข้าสู่ระบบ",
      "confirmation_period_expired": "จำเป็นต้องได้รับการยืนยันในเวลา %{period}, กรุณาทำใหม่อีกครั้ง",
      "expired": "การยืนยันหมดอายุแล้ว กรุณาขอใหม่อีกครั้ง",
      "not_found": "ไม่พบอีเมลผู้ใช้งานนี้",
      "not_locked": "ไม่ถูกล็อก",
      "not_saved": "%{count} ผิดพลาด %{resource} นี้ถูกห้ามบันทึก:",
      "taken": "ใช้งานไปเเล้ว"
    }
  },
  "feedback": {
    "body": "เนื้อหา",
    "comment": "ความคิดเห็น",
    "created_at": "วันที่แจ้ง",
    "created_notice": "ข้อเสนอแนะของท่านถูกส่งสำเร็จ",
    "destroyed_notice": "ลบข้อเสนอแนะสำเร็จ",
    "hospital": "โรงพยาบาล",
    "info": "รายละเอียด",
    "list": "รายการปัญหา/ข้อเสนอแนะ",
    "new": "แจ้งปัญหา/ข้อเสนอแนะ",
    "no_data": "ไม่มีข้อมูลปัญหา/ข้อเสนอแนะ ในขณะนี้",
    "type": "ประเภท",
    "user": "ผู้แจ้ง"
  },
  "footer": {
    "about_us": "เกี่ยวกับเรา",
    "contact_us": "ติดต่อเรา",
    "get_started": "เริ่มต้น",
    "navigation": "นำทาง",
    "version": "เวอร์ชัน: %{version}"
  },
  "form": {
    "email_already_registered": "อีเมลนี้ถูกใช้งานแล้ว",
    "email_must_unique": "อีเมลต้องไม่ซ้ำกัน",
    "enter_valid_value": "กรุณากรอกข้อมูลให้ถูกต้อง",
    "enter_value": "กรุณากรอก",
    "hospital_not_found": "ไม่พบสถานพยาบาล",
    "kpi_can_only_be_decimal": "ตัวชี้วัดนี้ สามารถกรอกได้แค่จำนวนทศนิยมเท่านั้น",
    "kpi_can_only_be_integer": "ตัวชี้วัดนี้ สามารถกรอกได้แค่จำนวนเต็มเท่านั้น",
    "kpi_can_only_be_numeral": "ตัวชี้วัดนี้ สามารถกรอกได้แค่จำนวนนับเท่านั้น",
    "kpi_can_only_be_positive_decimal": "ตัวชี้วัดนี้ สามารถกรอกได้แค่จำนวนทศนิยมบวกเท่านั้น",
    "name_must_unique": "ต้องไม่เป็นข้อมูลคนเดียวกัน",
    "not_yet_certification_process": "ยังไม่เข้ากระบวนการรับรอง",
    "select_atleast_one_kpi": "กรุณาเลือกตัวชี้วัดอย่างน้อย 1 ตัวชี้วัด",
    "select_date": "กรุณาเลือกวันที่"
  },
  "home": {
    "from": "จาก %{hospital_name}",
    "welcome": "ยินดีต้อนรับ %{name}"
  },
  "hospital": {
    "actived": "สถานะการใช้งาน",
    "actual_size": "จำนวนเตียงที่เปิดใช้จริง",
    "add_data": "เพิ่มสมาชิก",
    "add_data_instruction": "กดปุ่มเพิ่มสมาชิก เพื่อเพิ่มข้อมูล",
    "address_amphoe_changwat": "ที่อยู่ (อำเภอ, จังหวัด)",
    "address_en": "ที่อยู่สถานพยาบาล (English)",
    "address_th": "ที่อยู่สถานพยาบาล (ภาษาไทย)",
    "all_government_group_unit": "ภาครัฐทั้งหมด",
    "amphoe": "อำเภอ/เขต",
    "area": "อยู่ในเขตบริการ",
    "bed": "เตียง",
    "bed_size": "ขนาดเตียง",
    "bill_seq": "เลขที่ใบจ่ายเงิน",
    "budget_year": "ปีงบประมาณ",
    "cert_certified_date": "วันที่ได้รับการรับรอง",
    "cert_exp_date": "วันที่การรับรองหมดอายุ",
    "certificate": "ผ่านระบบการรับรองอื่นๆ ที่ไม่ใช่ HA (โปรดระบุถ้ามี)",
    "changwat": "จังหวัด",
    "code": "รหัสสถานพยาบาล",
    "code_alt": "รหัส รพ.",
    "company_code": "Company Code",
    "contact_info": "ข้อมูลการติดต่อ",
    "coop1": "ผู้ประสานงานโครงการ THIP คนที่ 1",
    "coop1_first_name": "ชื่อ ผู้ประสานงานคนที่ 1",
    "coop1_last_name": "สกุล ผู้ประสานงานคนที่ 1",
    "coop1_pre_name": "คำนำหน้าชื่อ ผู้ประสานงานคนที่ 1",
    "coop2": "ผู้ประสานงานโครงการ THIP คนที่ 2",
    "coop2_first_name": "ชื่อ ผู้ประสานงานคนที่ 2",
    "coop2_last_name": "สกุล ผู้ประสานงานคนที่ 2",
    "coop2_pre_name": "คำนำหน้าชื่อ ผู้ประสานงานคนที่ 2",
    "coop_email": "อีเมล",
    "coop_name": "ชื่อผู้ประสานงานโครงการ",
    "coop_phone_number": "หมายเลขโทรศัพท์",
    "created_notice": "เพิ่มสถานพยาบาลสำเร็จ",
    "destroyed_notice": "ลบสถานพยาบาลสำเร็จ",
    "director": "ผอ./ ผู้บริหารสูงสุด",
    "director_first_name": "ชื่อ ผอ./ ผู้บริหารสูงสุด",
    "director_last_name": "สกุล ผอ./ ผู้บริหารสูงสุด",
    "director_name": "ชื่อผอ./ผู้บริหารสูงสุด",
    "director_pre_name": "คำนำหน้าชื่อ ผอ./ ผู้บริหารสูงสุด",
    "download_invoice": "ดาวน์โหลดใบชำระเงิน",
    "download_register_form": "ดาวน์โหลดใบลงทะเบียน",
    "e_tax_mail": "อีเมลสำหรับส่งใบกำกับภาษีอิเล็กทรอนิกส์ (e-Tax)",
    "edit": "แก้ไขสถานพยาบาล",
    "edit_user": "แก้ไขผู้ใช้งาน",
    "email": "อีเมล",
    "email_report": "อีเมล์",
    "empty_data": "ไม่มีข้อมูลสมาชิก",
    "fax": "หมายเลขแฟกซ์ของสถานพยาบาล",
    "fee": "ค่าลงทะเบียน",
    "form": "ฟอร์มข้อมูลสถานพยาบาลสมาชิก",
    "group": "ประเภทสถานพยาบาล",
    "group_unit": "ระดับศักยภาพ",
    "ha_certified_date": "วันที่ได้รับการรับรอง",
    "ha_expire_date": "วันที่การรับรองหมดอายุ",
    "ha_status": "สถานะการรับรอง HA",
    "hos_location": "ที่ตั้งสถานพยาบาล",
    "hospital_number": "จำนวน รพ.",
    "hospital_programs": "โปรแกรม อื่น ๆ",
    "info": "ข้อมูลสถานพยาบาล",
    "invoice_name": "ชื่อในการออกใบเสร็จ",
    "kpi_limitation": "แจ้งข้อจำกัดการเลือกตัวชี้วัดภาคบังคับของโครงการฯ",
    "kpi_start_round": "เริ่มบันทึกข้อมูลตั้งแต่",
    "last_month_submit": "เดือนที่ส่งข้อมูลล่าสุด",
    "latitude": "ละติจูด",
    "location": "พิกัด",
    "longitude": "ลองจิจูด",
    "name": "ชื่อสถานพยาบาล",
    "name_alt": "ชื่อ รพ.",
    "name_en": "ชื่อสถานพยาบาล (English)",
    "name_or_code": "ชื่อหรือรหัสสถาพยาบาล",
    "name_th": "ชื่อสถานพยาบาล (ภาษาไทย)",
    "new": "เพิ่มสถานพยาบาล",
    "new_member": "สมาชิกใหม่",
    "no_data_has_sent": "ยังไม่มีการส่งข้อมูล",
    "no_data_in_budget_year": "ไม่มีข้อมูลในปีงบประมาณ",
    "not_found": "ไม่พบสถานพยาบาล",
    "not_found_and_use_current_code": "ไม่พบสถานพยาบาล คลิ๊กเพื่อใช้ %{code} เป็นรหัสสถานพยาบาล",
    "not_found_in_budget_year": "ไม่มีข้อมูลการลงทะเบียนในปีงบประมาณ %{budget_year}",
    "not_found_info": "ไม่พบข้อมูล",
    "not_registered": "ยังไม่ขึ้นทะเบียน",
    "note": "หมายเหตุ",
    "other_certificate": "ผ่านระบบการรับรองอื่นๆ ที่ไม่ใช่ HA",
    "other_info": "ข้อมูลอื่นๆ",
    "payment_date": "วันที่จ่ายเงิน",
    "payment_note": "ที่อยู่ในการออกใบเสร็จ",
    "payment_postal_code": "รหัสไปรษณีย์",
    "payment_sequence": "ลำดับเลขที่จ่ายเงิน",
    "phone_number": "หมายเลขโทรศัพท์",
    "please_select_programs": "กรุณาเลือกโปรแกรม",
    "postal_code": "รหัสไปรษณีย์",
    "print_invoice": "พิมพ์ใบชำระเงิน",
    "project": "โครงการร่วม",
    "qic_phone_number": "หมายเลขโทรศัพท์ศูนย์พัฒนาคุณภาพของสถานพยาบาล",
    "quic_email": "อีเมลของศูนย์พัฒนาคุณภาพของสถานพยาบาล",
    "register_date": "วันที่อนุมัติ",
    "register_successful": "การลงทะเบียนเสร็จสิ้น",
    "register_successful_description_1": "กรุณารอการอนุมัติจากผู้ดูแลระบบ",
    "register_successful_description_2": "ระบบจัดส่งรายละเอียดการลงทะเบียนไปยัง Email ผู้ประสานงานโครงการ THIP",
    "register_summary": "สรุปข้อมูลการลงทะเบียน",
    "register_trial_successful": "การลงทะเบียนทดลองใช้เสร็จสิ้น",
    "register_trial_successful_description_1": "มีอายุทดลองใช้ %{trial_days} วัน",
    "register_trial_successful_description_2": "ถึง (%{expiry_date})",
    "register_type": "ประเภทการลงทะเบียน",
    "registered": "สถานพยาบาลนี้ได้ทำการลงทะเบียนสมาชิกเรียบร้อยแล้ว",
    "registered_size": "จำนวนเตียงที่ขออนุญาต",
    "renew_member": "ต่ออายุ",
    "rollback": "ปลดล็อคการส่งข้อมูล",
    "search": "ค้นหาสถานพยาบาล",
    "search_instruction": "กรอกข้อมูลรหัสสถานพยาบาลให้เป็นตัวเลข 5หลัก ท่านสามารถค้นรหัสสถานพยาบาลได้จาก www.thcc.or.th หรือ ติดต่อ สรพ. ที่ 0-2832-9505",
    "select_indicator": "เลือกตัวชี้วัด",
    "selected_kpi": "ตัวชี้วัดที่เลือก",
    "sent_kpi": "ตัวชี้วัดที่ส่ง",
    "size": "ขนาดสถานพยาบาล",
    "start_round": "เริ่มบันทึกข้อมูล",
    "tambon": "ตำบล/แขวง",
    "tax_id": "เลขประจำตัวผู้เสียภาษี",
    "title": "โรงพยาบาล",
    "total": "จำนวน รพ.ทั้งหมด",
    "trial": "ทดลองใช้",
    "unit": "สังกัด",
    "updated_notice": "แก้ไขสถานพยาบาลสำเร็จ"
  },
  "hospital_group": {
    "created_notice": "เพิ่มกลุ่มสถานพยาบาลสำเร็จ",
    "destroyed_notice": "ลบกลุ่มสถานพยาบาลสำเร็จ",
    "updated_notice": "แก้ไขกลุ่มสถานพยาบาลสำเร็จ"
  },
  "invoice": {
    "accept_for_amount": "รับชำระเงินตามจำนวนข้างต้นเท่านั้น",
    "address": "ที่อยู่",
    "amount": "จำนวนเงิน",
    "amount_en": "Amount",
    "amount_to_pay": "จำนวนเงินที่ต้องชำระ",
    "bank": "ธนาคาร",
    "bank_name": "ชื่อธนาคารและสาขา",
    "bank_part": "ส่วนของธนาคาร",
    "bill_no": "Bill No. / Ref.1",
    "branch": "สาขา",
    "branch_en": "Bank/Branch",
    "branch_id": "รหัสสาขา(สำนักงาน)",
    "cash": "เงินสด",
    "cash_en": "Cash",
    "character": "ตัวอักษร",
    "chq_due_date": "เช็คลงวันที่",
    "chq_due_date_en": "Chq Due Date",
    "chq_no": "เลขที่เช็ค",
    "chq_no_en": "Chq No.",
    "company_code": "Company Code",
    "counter_only_ktb": "การชำระที่เคาน์เตอร์ สำหรับธนาคารกรุงไทยเท่านั้น",
    "customer_name": "ชื่อลูกค้า",
    "customer_path": "ส่วนของลูกค้า",
    "deputy_director": "รองผู้อำนวยการ",
    "e_payment": "ช่องทางอิเล็กทรอนิกส์",
    "expire_after_due_date": "ไม่รับชำระเงินหลังวันที่กำหนด",
    "for": "ค่าใช้จ่ายสำหรับ",
    "for_budget_year": "ค่าบำรุงสมาชิกโครงการโรงพยาบาลเพื่อความปลอดภัยของผู้ป่วย บุคลากรสาธารณสุข และประชาชน 3P Healthcare Membership ประจำปีงบประมาณ %{budget_year}",
    "for_hospital": "ค่าบำรุงสมาชิกโครงการโรงพยาบาลเพื่อความปลอดภัยของผู้ป่วย บุคลากรสาธารณสุข และประชาชน 3P Healthcare Membership ประจำปีงบประมาณ %{budget_year}",
    "form_instruction": "กรุณากรอกเอกสารนี้ให้ครบถ้วน แล้วนำไปชำระได้ที่เคาน์เตอร์ธนาคารกรุงไทย",
    "get_money_date": "วันที่รับเงิน",
    "head_of_financial_accounting": "หัวหน้าฝ่ายการเงินการบัญชี",
    "issuer": "ผู้ออกใบแจ้งรายการจ่ายเงิน",
    "ktb_received_from_thip": "KTB รับเงินจาก THIP",
    "list": "รายการ",
    "not_include_vat": "จำนวนไม่รวมVat",
    "note": "หมายเหตุ : สถาบันได้รับการยกเว้นหักภาษี ณ ที่จ่าย",
    "pay_by": "รับเงินจาก",
    "payment_date": "วันที่ชำระเงิน",
    "payment_method": "1=เงินสด 2=เช็ค 3=โอน",
    "payment_type": "ประเภทรายการรับเงิน",
    "print_at": "พิมพ์ ณ วันที่",
    "receipt_no": "เลขที่ใบเสร็จรับเงิน",
    "ref_1": "Ref.1",
    "ref_2": "Ref.2",
    "tax_id": "รหัสประจำตัวผู้เสียภาษี",
    "title": "ใบแจ้งรายการจ่ายเงิน",
    "total": "รวมเป็นเงินทั้งสิ้น",
    "total_amount": "ยอดรวม จำนวนเงินที่ชำระ",
    "total_amount_bath": "จำนวนเงินรวม (บาท)",
    "total_include_vat": "จำนวนเงินรวมVat",
    "total_payment_en": "Total Payment",
    "transaction_date": "วันที่ทำรายการ : %{date}",
    "unit_amount": "จำนวนหน่วย",
    "vat": "Vat%",
    "vat_amount": "จำนวนVat"
  },
  "kpi": {
    "above": "ขึ้นไป",
    "actived": "สถานะการใช้งาน",
    "actived_date": "วันเดือนปี ที่เริ่มใช้ตัวชี้วัด",
    "add": "เพิ่มตัวชี้วัด",
    "add_group": "เพิ่มกลุ่มตัวชี้วัด",
    "add_instruction": "กดปุ่มเพิ่มตัวชี้วัด เพื่อเพิ่มตัวชี้วัด",
    "add_sub_group": "เพิ่มกลุ่มย่อย",
    "approval_status": "สถานะการอนุมัติ",
    "audit": {
      "hospital_list_not_sent_this_month": "รายชื่อสถานพยาบาลที่ยังไม่ส่งเดือนนี้",
      "hospital_list_sent_this_month": "รายชื่อสถานพยาบาลที่ส่งเดือนนี้แล้ว",
      "label": "ตรวจสอบการบันทึกตัวชี้วัด",
      "level": "ระดับคุณภาพ",
      "sent_16th": "รายงานการส่งข้อมูล ณ วันที่ 16",
      "verification": "การตรวจสอบ"
    },
    "benchmark": "Benchmark (ค่า/แหล่งอ้างอิง/ปี)",
    "code": "รหัสตัวชี้วัด",
    "continuous": "การเชื่อมโยงค่าระหว่างงวด",
    "created_notice": "เพิ่มตัวชี้วัดสำเร็จ",
    "data_of_hospital_save_successful": "ข้อมูลของ %{hospital_name} ในช่วง %{round} ได้บันทึกเข้าระบบแล้ว",
    "denominator": "ตัวหาร",
    "denominator_detail": "รายละเอียดตัวหาร",
    "destroyed_notice": "ลบตัวชี้วัดสำเร็จ",
    "disease_code": "รหัสโรค / รหัสหัตถการที่เกี่ยวข้อง (ตัวตั้ง, ตัวหาร)",
    "down": "ลงไป",
    "edit": "แก้ไขตัวชี้วัด",
    "edit_formula": "แก้สูตรตัวชี้วัด",
    "edit_group": "แก้ไขกลุ่มตัวชี้วัด",
    "flag_from_thip_1": "ตัวชี้วัดจาก THIP I",
    "formula": {
      "created_notice": "เพิ่มสูตรคำนวณสำเร็จ",
      "destroyed_notice": "ลบสูตรคำนวณสำเร็จ",
      "label": "สูตรคำนวณ",
      "updated_notice": "แก้ไขสูตรคำนวณสำเร็จ"
    },
    "formula_detail": "รายละเอียดสูตรคำนวณ",
    "formula_kpi": "สูตรในการคำนวณตัวชี้วัด",
    "from": "ตั้งแต่",
    "group": {
      "created_notice": "เพิ่มกลุ่มตัวชี้วัดสำเร็จ",
      "destroyed_notice": "ลบกลุ่มตัวชี้วัดสำเร็จ",
      "updated_notice": "แก้ไขกลุ่มตัวชี้วัดสำเร็จ"
    },
    "group_detail": "ข้อมูลกลุ่มตัวชี้วัด",
    "group_info": "รายละเอียดกลุ่ม",
    "group_info_kpi": "รายละเอียดกลุ่มตัวชี้วัด",
    "group_name": "ชื่อกลุ่มตัวชี้วัด (en)",
    "group_name_th": "ชื่อกลุ่มตัวชี้วัด",
    "group_parent": "หมวดตัวชี้วัด",
    "history": {
      "fill_and_sent": "กรอกและกดส่งเข้าสู่ระบบ",
      "fill_not_sent": "กรอกแต่ไม่ได้กดส่งเข้าสู่ระบบ",
      "info_of": "ข้อมูลของ",
      "no_data": "ไม่มีข้อมูลการบันทึกตัวชี้วัดในขณะนี้",
      "not_fill": "ยังไม่ได้กรอกข้อมูล",
      "rollback_not_sent": "ปลดล็อคและไม่ได้กดส่งเข้าสู่ระบบ"
    },
    "hospital_select": "จำนวน รพ.สมาชิกที่เลือกตัวชี้วัด",
    "hospital_send": "จำนวน รพ.สมาชิกที่ส่งตัวชี้วัด",
    "info": "ข้อมูลตัวชี้วัด",
    "input_type": "รูปแบบการบันทึกข้อมูล",
    "interpret_method": "วิธีการแปลผล",
    "label": "สัญลักษณ์ของข้อมูลตัวชี้วัด",
    "less": "น้อยกว่า",
    "less_is_better": "ยิ่งน้อยยิ่งดี",
    "less_than": "แต่น้อยกว่า",
    "level_1": "ระดับ 1",
    "level_2": "ระดับ 2",
    "level_3": "ระดับ 3",
    "level_4": "ระดับ 4",
    "level_5": "ระดับ 5",
    "list": "รายการตัวชี้วัด",
    "main_group": "กลุ่มหลัก",
    "meaning": "นิยาม / คำอธิบาย / ความหมายของตัวชี้วัด (ภาษาอังกฤษ)",
    "meaning_label": "นิยาม / คำอธิบาย / ความหมายของตัวชี้วัด",
    "meaning_th": "นิยาม / คำอธิบาย / ความหมายของตัวชี้วัด (ภาษาไทย)",
    "median_to_percentile75": "Median : %{median} ถึง Percentile75 : %{percentile75}",
    "min_to_percentile25": "Min : %{min} ถึง Percentile25 : %{percentile25}",
    "more_is_better": "ยิ่งมากยิ่งดี",
    "more_than": "แต่มากกว่า",
    "name": "ชื่อตัวชี้วัด",
    "name_en": "ชื่อตัวชี้วัด (ภาษาอังกฤษ)",
    "name_th": "ชื่อตัวชี้วัด (ภาษาไทย)",
    "no_data": "ไม่มีข้อมูลตัวชี้วัด",
    "not_found": "ไม่พบตัวชี้วัด",
    "not_found_save_data": "ไม่มีข้อมูลการบันทึกตัวชี้วัด",
    "not_less_than": "แต่ไม่ต่ำกว่า",
    "not_more_than": "แต่ไม่เกิน",
    "not_over": "ไม่เกิน",
    "note": "หมายเหตุ",
    "numerator": "ตัวตั้ง",
    "numerator_detail": "รายละเอียดตัวตั้ง",
    "objective": "วัตถุประสงค์ของการมีตัวชี้วัดนี้",
    "other_detail": "รายละเอียดอื่นๆ",
    "percentile25_to_median": "Percentile25 : %{percentile25} ถึง Median : %{median}",
    "percentile75_to_max": "Percentile75 : %{percentile75} ถึง Max : %{max}",
    "peroid": "ความถี่ในการจัดทำข้อมูล",
    "please_select": "กรุณาเลือกตัวชี้วัด",
    "record_method": "ข้อมูลที่ต้องการ (ตัวตั้ง, ตัวหาร)",
    "ref_code": "รหัสการเชื่อมโยงตัวแปร",
    "reject_reason": "เหตุผลที่ปฏิเสธ",
    "relate": {
      "disable_reason": "บันทึกเหตุผลการยกเลิกเชื่อมโยงตัวชี้วัด",
      "title": "เชื่อมโยงตัวชี้วัด"
    },
    "rollback": {
      "reason": "เหตุผลการขอปลดล็อค",
      "reason_1": "เหตุผล 1 : รวบรวมข้อมูลได้ไม่ทันตามรอบระยะเวลา",
      "reason_2": "เหตุผล 2 : กรอกข้อมูลครบแล้วแต่ยังไม่ได้กดส่งเข้าสู่ระบบ",
      "reason_3": "เหตุผล 3 : ขอเพิ่มเติมข้อมูล เพราะข้อมูลที่ส่งไปแล้วไม่ครบถ้วน",
      "reason_4": "เหตุผล 4 : ขอแก้ไขข้อมูล เพราะข้อมูลที่ส่งไปแล้วมีความผิดพลาด",
      "reason_5": "เหตุผล 5 : มีปัญหาด้านการดำเนินงาน หรือทีมทำงาน ภายในโรงพยาบาล",
      "reason_6": "เหตุผล 6 : อื่นๆ"
    },
    "save_successful": "บันทึกเข้าระบบแล้ว",
    "search": "ค้นหาตัวชี้วัด",
    "select_percentage": "อัตราของการเลือกตัวชี้วัด",
    "send_percentage": "อัตราของการส่งตัวชี้วัด",
    "sheet": {
      "can_not_import_abort_kpi": "ไม่สามารถนำเข้าข้อมูลของตัวชี้วัดที่ถูกยกเลิก",
      "export_template": "ดาวน์โหลดไฟล์ต้นแบบ",
      "import_data": "นำเข้าข้อมูล",
      "must_be_number": "ต้องเป็นจำนวน",
      "must_be_number_alt": "ต้องเป็นจำนวน ค่ะ",
      "no_data": "ไม่มีข้อมูลตัวชี้วัดในขณะนี้",
      "no_wait_for_save_data": "ไม่มีตัวชี้วัดที่รอการบันทึกในขณะนี้",
      "select_file": "เลือกไฟล์",
      "upload": "อัพโหลด",
      "warning": "แจ้งเตือน"
    },
    "source": "ที่มาของตัวชี้วัด / Reference",
    "special_group": "ตัวชี้วัดกลุ่มพิเศษ",
    "summary": {
      "no_data": "ไม่มีข้อมูลตัวชี้วัดในขณะนี้"
    },
    "title": "ตัวชี้วัด",
    "type": "ประเภทตัวชี้วัด",
    "unit": "หน่วยวัดของตัวชี้วัด",
    "unlock_date": "วันที่ขอปลดล็อค",
    "unlock_month": "เดือนที่ขอปลดล็อค",
    "unlock_reason": "เหตุผลขอปลดล็อค",
    "updated_at": "วันเดือนปี ที่ปรับปรุงครั้งล่าสุด",
    "updated_notice": "แก้ไขตัวชี้วัดสำเร็จ",
    "updated_reason": "เหตุผลของการปรับปรุง"
  },
  "kpi_history_tab": {
    "all": "ทั้งหมด",
    "filled": "กรอกแล้ว",
    "not_fill": "ยังไม่ได้กรอก",
    "overview": "ภาพรวมการบันทึกตัวชี้วัด",
    "title": "ประวัติการบันทึกตัวชี้วัด"
  },
  "kpi_sheet": {
    "analysis": "ขณะนี้เป็นช่วงเวลาสรุปส่งข้อมูลตัวชี้วัดเดือน",
    "analysis_month": "ขณะนี้เป็นช่วงเวลาวิเคราะห์ข้อมูลตัวชี้วัดของรอบข้อมูลเดือน %{month}",
    "completed": "สถานพยาบาลของคุณได้ส่งข้อมูลตัวชี้วัดครบถ้วนแล้ว",
    "not_yet": "สถานพยาบาลของคุณยังไม่ถึงช่วงส่งข้อมูลตัวชี้วัด",
    "send_failed_notice": "ส่งข้อมูลไม่สำเร็จ",
    "sended_notice": "ส่งข้อมูลสำเร็จ",
    "upload_failed_notice": "อัพโหลดข้อมูลไม่สำเร็จ",
    "uploaded_notice": "อัพโหลดข้อมูลสำเร็จ"
  },
  "kpi_sheets_size": {
    "recorder": "คุณมีข้อมูลตัวชี้วัดรอการบันทึก %{size} รอบการบันทึกข้อมูล",
    "sender": "คุณมีข้อมูลตัวชี้วัดรอการส่ง %{size} รอบการส่งข้อมูล"
  },
  "mailer": {
    "7_days": "7 วัน",
    "admin": "ผู้ดูแลโครงการ THIP",
    "already": "เรียบร้อยแล้ว",
    "because": "เนื่องจาก",
    "before_12am": "ก่อน 23:50น.",
    "before_due_date": "ก่อนวันที่สิ้นสุดการส่งข้อมูล",
    "before_trial_expiry": "บัญชีสมาชิกทดลองใช้ระบบ THIP ของสถานพยาบาล %{hospital_name} กำลังจะหมดอายุในวันที่ %{expiry_date}",
    "by": "โดยคุณ",
    "by_alt": "ทาง คุณ",
    "cannot": "ให้ได้",
    "data_for_month": "ข้อมูลของเดือน",
    "due_date": "โดยสิ้นสุดการกรอกและส่งข้อมูลภายใน",
    "end": "จึงเรียนมาเพื่อทราบ",
    "from": "จาก",
    "give_to": "ให้เเก่",
    "ha_cannot_rollback": "ทาง สรพ ไม่สามารถปลดล็อคข้อมูลของเดือน",
    "ha_rollback": "ทาง สรพ ได้ปลดล็อคข้อมูลของเดือน",
    "ha_rollback_notify": "ทาง สรพ จะแจ้งให้ทราบว่าการขอปลดล็อคข้อมูลของเดือน",
    "have_question": "หากคุณมีข้อสงสัยเกี่ยวกับโครงการ THIP โปรดติดต่อ",
    "last_day_of_rollback": "วันสุดท้ายของการขอปลดล็อค ของเดือน",
    "last_day_of_submission": "วันสุดท้ายของรอบการส่งข้อมูลของเดือน",
    "new_feedback": "มีการส่ง ปัญหา/ข้อเสนอแนะ",
    "new_registration": "มีการยื่นขอ ลงทะเบียนเป็นสมาชิกโครงการ THIP",
    "new_rollback_for_month": "มีการแจ้งขอปลดล็อคข้อมูลของเดือน",
    "password_en": "Password",
    "persuade_before_trial_expiry": "หากสมาชิกทดลองใช้ มีความต้องการใช้งานระบบ THIP กรุณาลงทะเบียนใช้งานจริง ก่อนวันที่ %{expiry_date}",
    "please_completed_before": "โปรดดำเนินการ ให้แล้วเสร็จภายใน",
    "please_fill_and_send_before": "กรุณากรอกและส่งข้อมูล ให้แล้วเสร็จภายใน",
    "remaining": "มีเวลาอีก",
    "rollback_month": "ได้ปลดล็อคข้อมูลเดือน",
    "time_up": "หมดเวลาเป็นที่เรียบร้อย",
    "to_admin": "เรียน ผู้ดูแลระบบ",
    "to_hospital_member": "เรียน รพ.สมาชิกระบบ THIP",
    "to_trial_user": "เรียน สมาชิกทดลองใช้ระบบ THIP",
    "to_user": "เรียน สมาชิกระบบ THIP",
    "to_user_with_name": "เรียน คุณ%{name} จากสถานพยาบาล %{hospital_name}",
    "url": "URL",
    "user_info": "คุณสามารถเข้าใช้งานระบบได้แล้วในขณะนี้ โดยใช้ข้อมูลต่อไปนี้ในการ login เข้าสู่ระบบ",
    "user_info_alt": "คุณสามารถเข้าใช้งานระบบ โดยใช้ Username และ Password ใหม่ ดังนี้",
    "username_en": "Username",
    "welcome_to_thip": "ยินดีต้อนรับเข้าสู่ระบบ Thailand Hospital Indicator Program",
    "welcome_to_thip_alt": "เข้าสู่ระบบ Thailand Hospital Indicator Program",
    "welcome_user": "ยินดีต้อนรับ คุณ%{name} จากสถานพยาบาล %{hospital_name}"
  },
  "member_tab": {
    "all": "สมาชิกทั้งหมด",
    "banned": "สมาชิกที่ถูกระงับการใช้งาน",
    "not_approved": "สมาชิกที่ยังไม่ได้อนุมัติการลงทะเบียน",
    "payment_status": "การชำระเงินของสมาชิก",
    "trial": "สมาชิกทดลองใช้"
  },
  "not_member": "ท่านยังไม่ได้เป็นสมาชิกของปีงบประมาณปัจจุบัน",
  "post": {
    "add_instruction": "กดปุ่มเพิ่มข่าว เพื่อเพิ่มข้อมูล",
    "attention": "โปรดทราบ",
    "cover_recommend_size": "ขนาดภาพหน้าปกที่แนะนำคือ 1280 x 720",
    "created_notice": "เพิ่มโพสต์สำเร็จ",
    "destroyed_notice": "ลบโพสต์สำเร็จ",
    "edit": "แก้ไขโพสต์",
    "info": "รายละเอียดโพสต์",
    "new": "สร้างโพสต์ใหม่",
    "no_data": "ไม่มีข้อมูลข่าว",
    "read_all": "อ่านทั้งหมด",
    "title": "โพสต์",
    "updated_notice": "แก้ไขโพสต์สำเร็จ"
  },
  "program": {
    "created_notice": "เพิ่มโปรเเกรมสำเร็จ",
    "destroyed_notice": "ลบโปรเเกรมสำเร็จ",
    "updated_notice": "แก้ไขโปรเเกรมสำเร็จ"
  },
  "recorder_kpi_sheets_completed": "คุณไม่มีข้อมูลตัวชี้วัดค้างกรอก",
  "recorder_kpi_sheets_not_yet": "ยังไม่ถึงรอบของการกรอกข้อมูลตัวชี้วัด",
  "registered_hospital": {
    "created_notice": "เพิ่มสมาชิกสำเร็จ",
    "destroyed_notice": "ลบสมาชิกสำเร็จ",
    "updated_notice": "แก้ไขสมาชิกสำเร็จ"
  },
  "registration": {
    "agreement_1": "มีความมุ่งมั่นที่จะใช้ข้อมูลจากระบบ เพื่อการพัฒนาคุณภาพของสถานพยาบาลภายใต้เครื่องมือพัฒนากลไกการจัดการคุณภาพบริการเพื่อเพิ่มประสิทธิภาพ และลดความเหลื่อมล้ำของคุณภาพและความปลอดภัย หรือ 3P Safety",
    "agreement_2": "รับรองว่าข้อมูลที่ได้จัดส่งเข้าสู่ระบบสารสนเทศภายใต้เครื่องมือพัฒนากลไกการจัดการคุณภาพบริการเพื่อเพิ่มประสิทธิภาพ และลดความเหลื่อมล้ำของคุณภาพและความปลอดภัย 3P Safety เป็นข้อมูลที่โรงพยาบาลได้ดำเนินการตรวจสอบข้อมูลและมั่นใจในข้อมูลสารสนเทศว่ามีความแม่นยำ ถูกต้อง คงสภาพ เชื่อถือได้และเป็นปัจจุบัน",
    "agreement_3": "ยินยอมให้สถาบันรับรองคุณภาพสถานพยาบาล (องค์การมหาชน) นำข้อมูลสารสนเทศ ภายใต้เครื่องมือพัฒนากลไกการจัดการคุณภาพบริการเพื่อเพิ่มประสิทธิภาพ และลดความเหลื่อมล้ำของคุณภาพและความปลอดภัย 3P Safety ไปใช้ประโยชน์เพื่อเป็นข้อมูลอ้างอิงทางวิชาการและส่งเสริมการพัฒนาคุณภาพ ซึ่งเป็นไปตามระเบียบนโยบายการคุ้มครองข้อมูลส่วนบุคคล (Privacy Policy)",
    "agreement_4": "ยินยอมชำระค่าบำรุงการเข้าร่วมพัฒนากลไกการจัดการคุณภาพบริการเพื่อเพิ่มประสิทธิภาพ และลดความเหลื่อมล้ำของคุณภาพและความปลอดภัย 3P Safety ตามประกาศของสถาบันฯ",
    "agreement_5": "รับรองว่าข้อมูลที่มีการรายงาน และส่งเข้าสู่ระบบภายใต้เครื่องมือที่เลือกรวมถึงตัวชี้วัดที่เลือก จำนวน %{kpi_count} ตัว จะดำเนินการส่งตามระยะเวลา และรายละเอียดข้อมูลกำหนดอย่างครบถ้วน",
    "agreement_6": "และอื่น ๆ ภายใต้โครงการฯ ที่กำหนดไว้",
    "app_th_alt_regis": "โครงการโรงพยาบาลเพื่อความปลอดภัยของผู้ป่วยบุคลากรสาธารณสุข และประชาชน ประจำปี พ.ศ.%{budget_year}",
    "attention": "โปรดทราบ",
    "budget_year": "ลงทะเบียน/ต่ออายุสมาชิก ประจำปีงบประมาณ %{budget_year}",
    "consent": "ผู้ให้ความยินยอม",
    "currently_setting_on": "มีการตั้งค่าเปิดการลงทะเบียนในปีงบประมาณอื่นอยู่ในขณะนี้ คุณสามารถเปิดให้ลงทะเบียนได้ครั้งละ 1 ปีงบประมาณเท่านั้น",
    "date": "วันที่",
    "description": "หนังสือฉบับนี้ %{hospital_name} โดย %{director_name} ตำแหน่งผู้บริหารสูงสุด ซึ่งเป็นผู้มีอำนาจลงนามผูกพันกับสถาบัน รับรองคุณภาพสถานพยาบาล (องค์การมหาชน) เพื่อแสดงว่า %{hospital_name} สมัครใจและยินยอมปฏิบัติตามเงื่อนไขสำหรับเข้าร่วมโครงการระบบสารสนเทศเปรียบเทียบวัดระดับคุณภาพ โรงพยาบาล (THIP) ดังนี้ ",
    "description_regis": "ข้าพเจ้า %{director_name} ตำแหน่งผู้บริหารสูงสุด ซึ่งเป็นผู้มีอำนาจลงนามผูกพันกับสถาบันรับรองคุณภาพสถานพยาบาล (องค์การมหาชน) เพื่อแสดงว่า %{hospital_name} สมัครใจสมัครเข้าร่วม",
    "description_regis_2": "หนังสือฉบับนี้ %{hospital_name} โดย %{director_name} เบอร์โทร %{phone} และมอบหมายผู้รับผิดชอบ/ผู้ประสาน %{coop1_name} เบอร์โทร %{coop1_phone_number} เมล %{coop1_email} ยินยอมปฏิบัติตามเงื่อนไขสำหรับเข้าร่วมโครงการฯ ดังนี้",
    "destroyed_member_notice": "ลบสมาชิก สำเร็จ",
    "hospital_seal": "ประทับตราสถานพยาบาล",
    "label": "ลงทะเบียนใช้ระบบ",
    "register_successful_description_1": "กรุณารอการอนุมัติจากผู้ดูแลระบบ",
    "register_successful_description_2": "ระบบจัดส่งรายละเอียดการลงทะเบียนไปยัง Email ผู้ประสานงานโครงการ THIP",
    "renew": "ต่ออายุสมาชิก",
    "renew_instruction": "หากท่านต้องการเพิ่มผู้ใช้งานใหม่ ท่านสามารถเพิ่มโดยการเปลี่ยนข้อมูลจากผู้ใช้งานเก่าให้เป็นผู้ใช้งานใหม่ ระบบจะดำเนินการส่งรหัสผ่านไปยังอีเมลที่ท่านได้กำหนด",
    "renew_success": "ต่ออายุสมาชิกเสร็จสิ้น",
    "sign": "ลงชื่อ",
    "sub_title_regis": "บุคลากรสาธารณสุข และประชาชน ประจำปีงบประมาณ",
    "title": "ใบแจ้งความจำนงเข้าร่วมโครงการ",
    "title_regis": "ใบแจ้งความจำนงเข้าร่วมโครงการโรงพยาบาลเพื่อความปลอดภัยของผู้ป่วย",
    "title_with_app_name": "ใบแจ้งความจำนงเข้าร่วมโครงการ THIP",
    "trial_label": "ลงทะเบียนทดลองใช้ระบบ",
    "upgrade_membership_success": "ลงทะเบียนใช้งานจริงเสร็จสิ้น"
  },
  "reply": {
    "new": "ตอบกลับ",
    "title": "ตอบกลับ"
  },
  "rollback": {
    "approve": "อนุมัติ",
    "approve_notice": "การอนุมัติสำเร็จ",
    "approve_over_day_notice": "การอนุมัติไม่สำเร็จ รอบการของปลดล็อคเกินกำหนดของการออกรายงาน",
    "approve_request": "ยืนยันการปลดล็อคตามการยื่นคำร้อง",
    "by_hospital": "การปลดล็อคเฉพาะรายโรงพยาบาล",
    "hospital": "สถานพยาบาล",
    "hospital_info": "สถานพยาบาล: %{hospital}",
    "info": "รายละเอียดขอปลดล็อค",
    "no_data": "ไม่มีข้อมูลที่สามารถปลดล็อคระบบได้",
    "please_select_month": "กรุณาเลือก เดือนที่ต้องการ ปลดล็อค",
    "reason": "เหตุผลการขอปลดล็อค",
    "reason_info": "เหตุผลการขอปลดล็อค: %{reason}",
    "reject": "ปฏิเสธ",
    "reject_reason": "เหตุผลการปฏิเสธคำร้อง",
    "reject_request": "ปฏิเสธการปลดล็อคตามการยื่นคำร้อง",
    "request": "ขอปลดล็อคระบบเพื่อแก้ไขข้อมูล",
    "requester": "ผู้ขอปลดล็อค",
    "round": "รอบการขอปลดล็อค",
    "round_info": "รอบการขอปลดล็อค: %{round}",
    "save_reject_reason": "บันทึกเหตุผลการปฏิเสธคำร้อง",
    "unlock_notice": "การปลดล็อคสำเร็จ"
  },
  "rollback_tab": {
    "by_all": "การปลดล็อคทั้งระบบ",
    "by_hospital": "การปลดล็อคเฉพาะรายโรงพยาบาล",
    "by_request": "การปลดล็อคตามการยื่นคำร้อง"
  },
  "screened_out": {
    "approved": "อนุมัติ",
    "budget_year": "ปีงบประมาณ",
    "created_notice": "เพิ่มรายการยกเลิกตัวชี้วัดสำเร็จ",
    "destroyed_notice": "ลบรายการยกเลิกตัวชี้วัดสำเร็จ",
    "detail": "เพิ่มยกเลิกตัวชี้วัด",
    "hospital": {
      "code": "รหัสสถานพยาบาล",
      "name": "ชื่อสถานพยาบาล",
      "title": "สถานพยาบาล"
    },
    "kpi": {
      "code": "รหัสตัวชี้วัด",
      "name": "ชื่อตัวชี้วัด",
      "title": "ตัวชี้วัด"
    },
    "name": "ยกเลิกตัวชี้วัด",
    "no_data": "ไม่มีข้อมูลยกเลิกตัวชี้วัด",
    "start_month": "เดือนที่เริ่มมีผล",
    "updated_notice": "แก้ไขรายการยกเลิกตัวชี้วัดสำเร็จ"
  },
  "sender_kpi_sheets_completed": "คุณไม่มีข้อมูลตัวชี้วัดค้างส่ง",
  "sender_kpi_sheets_not_yet": "ยังไม่ถึงรอบของการส่งข้อมูลตัวชี้วัด",
  "session": {
    "feature_trial": "เปิดทดลองใช้งาน",
    "forgot_password": "ลืมรหัสผ่าน",
    "sign_in": "เข้าสู่ระบบ",
    "sign_in_to_app": "ลงชื่อเข้าใช้ระบบ",
    "sign_up": "ลงทะเบียน",
    "trial_sign_up": "ทดลองใช้งาน"
  },
  "sidebar_menu": {
    "about_hospital": "เกี่ยวกับสถานพยาบาล",
    "analysis": "รายงาน/วิเคราะห์",
    "change_password": "เปลี่ยนรหัสผ่าน",
    "feedback": "ปัญหา/ข้อเสนอแนะ",
    "home": "หน้าแรก",
    "hospital": "สถานพยาบาล",
    "kpi": "ข้อมูลตัวชี้วัด",
    "kpi_audit": "ตรวจสอบการบันทึกตัวชี้วัด",
    "kpi_history": "ประวัติการบันทึก ตัวชี้วัด",
    "kpi_screened_out": "ยกเลิกตัวชี้วัด",
    "member": "สมาชิก",
    "post": "โพสต์",
    "renew_member": "ต่ออายุสมาชิก",
    "send_data_to_erp": "ส่งข้อมูลไปยัง ERP",
    "settings": "ตั้งค่าระบบ",
    "unlock": "ปลดล็อคระบบเพื่อแก้ไขข้อมูล",
    "upgrade_membership": "ลงทะเบียนใช้งานจริง"
  },
  "special_group": {
    "created_notice": "เพิ่มกลุ่มพิเศษสำเร็จ",
    "destroyed_notice": "ลบกลุ่มพิเศษสำเร็จ",
    "updated_kpi_notice": "แก้ไขข้อมูลตัวชี้วัดสำเร็จ",
    "updated_notice": "แก้ไขกลุ่มพิเศษสำเร็จ"
  },
  "time": {
    "formats": {
    }
  },
  "time_with_zone": {
    "formats": {
    }
  },
  "user": {
    "actived": "สถานะการใช้งาน",
    "add": "เพิ่มผู้ใช้งาน",
    "add_instruction": "กดปุ่มเพิ่มผู้ใช้งาน เพื่อเพิ่มข้อมูล",
    "change_password": "เปลี่ยนรหัสผ่าน",
    "confirm_new_password": "ยืนยันรหัสผ่านใหม่",
    "created_notice": "เพิ่มผู้ใช้งานสำเร็จ",
    "delete_confirmation": "ต้องการลบสมาชิก %{user}",
    "destroyed_notice": "ลบผู้ใช้งานสำเร็จ",
    "edit": "แก้ไขผู้ใช้งาน",
    "email": "อีเมล",
    "first_name": "ชื่อ",
    "first_name_en": "ชื่อ (English)",
    "full_name": "ชื่อ - สกุล",
    "full_name_en": "ชื่อ - สกุล (English)",
    "hospital": "สถานพยาบาลต้นสังกัด",
    "info": "ข้อมูลผู้ใช้งาน",
    "last_name": "นามสกุล",
    "last_name_en": "นามสกุล (English)",
    "new_password": "รหัสผ่านใหม่",
    "no_data": "ไม่มีข้อมูลผู้ใช้งาน",
    "old_password": "รหัสผ่านเก่า",
    "phone_number": "เบอร์โทรศัพท์",
    "random_password": "สุ่มรหัสผ่าน",
    "type": "ประเภทผู้ใช้งาน",
    "updated_notice": "แก้ไขผู้ใช้งานสำเร็จ"
  }
});
